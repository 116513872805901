@media screen and (max-width: 1000px) {
    .dialog-container {
        width: 100% !important;
        border: unset !important;
    }
}

.logo {
    width: 100%;
}

.container-logo {
    display: flex;
    justify-content: center;
}

.dialog-container {
    border: 3px solid rgba(0, 0, 0, 0.1);
    width: 40%;
    height: 50%;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    max-width: 400px;
}

.auth-page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons-container {
    padding: 30px 0;
    box-sizing: border-box;
}

.button {
    width: 100%;
    color: black;
    display: flex;
    text-transform: capitalize;
    box-sizing: border-box;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: 0.25s;
    padding: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: unset;
    height: 70px;
}
.button svg path {
    transition: 0.25s;
}
.button:hover { background: #00142D; color: white;}
.button:hover svg path { fill: white}


.list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    justify-content: center;
}

.success{
    display: flex;
    font-weight: bold;
    font-size: large;
    margin: 15px 0;
    flex-direction: column;
    gap: 10px;
}